<template>
  <v-combobox
      v-model="selected"
      :items="items"
      item-text="name"
      label="Выберите скважину"
      :rules="rules"
      @change="itemSelected($event)"
      :disabled="this.deposit == null"
  ></v-combobox>
</template>

<script>
import selectorMixin from "@/components/modal/selector/mixin/selector-mixin";

export default {
  name: "WellSelector",
  mixins: [selectorMixin],
  props: ['deposit'],
  computed: {
    items() {
      if (this.deposit != null)
        return this.deposit.wells
      else
        return []
    }
  },
  watch: {
    deposit() {
      this.selected = null
      this.itemSelected(this.selected)
    }
  }
}
</script>
